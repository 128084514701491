import React from 'react';
import clsx from 'clsx';
import { transparentize } from 'color2k';
import { Gutter, Text, Video, Image, ModuleContainer } from 'ui';
import { getTextColor } from 'utils';
import { sizes } from '~/constants';
import { CmsModel } from 'api-types';
import styles from './M70Media.module.css';

export type M70MediaProps = CmsModel.M070MediaModule;

export const M70Media = ({ image, video, caption, ...rest }: M70MediaProps) => {
    const textColor = transparentize(getTextColor(), 0.1);

    const shouldDisplayImage = video && video.length > 0 ? false : true;

    const M70Tag = shouldDisplayImage ? 'figure' : 'div';

    return (
        <ModuleContainer hasPadding={true} {...rest}>
            <M70Tag className={clsx(styles.M70)}>
                {shouldDisplayImage && image ? (
                    <Image
                        {...image}
                        layout="responsive"
                        sizes={`${sizes.contentMaxWidth} ${sizes.contentMaxWidth}px, 100vw`}
                    />
                ) : null}
                {!shouldDisplayImage && video ? (
                    <Video controls={true} src={video} posterSrc={image?.src} />
                ) : null}
                {caption ? (
                    <Gutter as={shouldDisplayImage ? 'figcaption' : 'div'}>
                        <Text variant="caption" style={{ color: textColor }} children={caption} />
                    </Gutter>
                ) : null}
            </M70Tag>
        </ModuleContainer>
    );
};
