import React from 'react';
import clsx from 'clsx';
import { RawHtml, ModuleContainer, ModuleHeader } from 'ui';
import { CmsModel } from 'api-types';
import styles from './M100RichText.module.css';

export type M100RichTextProps = CmsModel.M100RichTextModule;

export const M100RichText = ({
    text, headline, secondaryColumn,
    backgroundColor = '#ffffff',
    ...rest
}: CmsModel.M100RichTextModule) => {
    const rootStyles = clsx(styles.root, {
        [styles.twoColumns]: secondaryColumn !== undefined && secondaryColumn.length > 0,
        [styles.singleColumn]: secondaryColumn === undefined || secondaryColumn.length === 0
    });

    const isDarkBackground = backgroundColor !== '#ffffff' && backgroundColor !== null;

    return (
        <div className={rootStyles} >
            <ModuleContainer {...rest} hasPadding={false}>
                <div style={{ backgroundColor }}
                    className={clsx(styles.innerContainer, {
                        [styles.withDarkBackground]: isDarkBackground
                    })}>
                    <div className={styles.headerAlignment} >
                        {headline && <ModuleHeader headline={headline} variant="display3" />}
                        <div>
                            <div className={clsx(styles.columns)}>
                                {text && (
                                    <RawHtml
                                        html={text}
                                        className={isDarkBackground && styles.withDarkBackground} />)
                                }
                                {secondaryColumn && (
                                    <RawHtml
                                        html={secondaryColumn}
                                        className={isDarkBackground && styles.withDarkBackground}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </ModuleContainer >
        </div>
    );
};