import React, { ComponentType } from 'react';
import dynamic from 'next/dynamic';
import { CmsModel } from 'api-types';

type Props = {
    elements?: CmsModel.ModuleBase[];
};

export type Blocks = {
    [key in CmsModel.ModuleTypes]?: ComponentType | any;
};

const Noop = ({ children }: { children: ComponentType }) => <>{children}</>;

const blocks: Blocks = {
    M010HeroModule: dynamic(() => import('../components/modules/M10Hero')),
    M020CategoriesModule: dynamic(() => import('../components/modules/M20Categories')),
    M021ReferencedCategoriesModule: dynamic(() => import('../components/modules/M21ReferencedCategories')),
    M060UspModule: dynamic(() => import('../components/modules/M60USP')),
    M070MediaModule: dynamic(() => import('../components/modules/M70Media')),
    M075MediaAndTextModule: dynamic(() => import('../components/modules/M75MediaText')),
    M080ContentCarrousel: dynamic(() => import('../components/modules/M80ContentCarousel')),
    M085ProductCarousel: dynamic(() => import('../components/modules/M085ProductCarousel')),
    M090QuoteModule: dynamic(() => import('../components/modules/M90Quote')),
    M100RichTextModule: dynamic(() => import('../components/modules/M100RichText')),
    M120AccordionModule: dynamic(() => import('../components/modules/M120Accordion')),
    M140ProductListModule: dynamic(() => import('../components/modules/M140ProductsList')),
    M141ProductListModule: dynamic(() => import('../components/modules/M140ProductsList')),
    M130ProductDetailModule: dynamic(() => import('../components/modules/M130ProductDetail')),
    M170SearchResultsModule: dynamic(() => import('../components/modules/M170SearchPage'), { ssr: false }),
    M171VehicleSearch: dynamic(() => import('../components/modules/M171VehicleSearch')),
    M150BasketModule: dynamic(() => import('../components/modules/M150Basket'), { ssr: false }),
    M160CheckoutModule: dynamic(() => import('../components/modules/M160Checkout'), { ssr: false }),
    M190ReceiptModule: dynamic(() => import('../components/modules/M190Receipt'), { ssr: false }),
    M200CancelOrder: dynamic(() => import('../components/modules/M200CancelOrder')),
};

const DynamicBlock = ({ element }: { element: CmsModel.ModuleBase }) => {
    const Block = blocks[element.type as keyof Blocks] || Noop;
    return <Block {...(element as object)} />;
};

export const DynamicBlocks = ({ elements }: Props) => {
    if (!elements?.length) {
        return null;
    }

    return (
        <>
            {elements?.map((element, index) => {
                return <DynamicBlock key={index} element={element} />;
            })}
        </>
    );
};

