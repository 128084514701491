import NextLink from 'next/link';
import React, { memo } from 'react';
import clsx from 'clsx';
import { Text, Image, ModuleContainer } from 'ui';
import { weakKey } from 'utils';
import { CmsModel } from 'api-types';
import styles from './M20Categories.module.css';

export type M20CategoriesProps = CmsModel.M020CategoriesModule;

/**
 * List of category and/or page links, Can be used as display information without links.
 * The layout is supposed to be used with small illustrations or icons.
 */
export const M20Categories = memo(({ categories, ...rest }: M20CategoriesProps) => {
    return (
        <ModuleContainer hasGutter {...rest}>
            <div className={clsx(styles.categories)}>
                {categories?.map((item) => {
                    const { target, url, title } = item.callToAction || {};

                    return (
                        <div className={styles.category} key={weakKey(item)}>
                            {item.image?.src && (
                                <Image
                                    {...item.image}
                                    alt={item.image?.alt}
                                    width={90}
                                    height={90}
                                    layout="fill"
                                    objectFit="cover"
                                />
                            )}
                            <div className={clsx(styles.content)}>
                                {url && (
                                    <NextLink href={url} passHref>
                                        <a
                                            target={target}
                                            title={title}
                                            className={clsx(styles.link)}
                                        >
                                            <Text
                                                children={item.title}
                                                color='neutralWhite'
                                                as='h2'
                                                fontWeight='regular'
                                                variant='display3'
                                            />
                                            <Text
                                                children={item.subtitle}
                                                color='neutralWhite'
                                                as='p'
                                            />
                                        </a>
                                    </NextLink>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </ModuleContainer>
    );
});
